@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap);
body {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
}

.loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.loader div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #1ab394;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader div:nth-child(1) {
    left: 8px;
    animation: loader1 0.4s infinite;
}
.loader div:nth-child(2) {
    left: 8px;
    animation: loader2 0.4s infinite;
}
.loader div:nth-child(3) {
    left: 32px;
    animation: loader2 0.4s infinite;
}
.loader div:nth-child(4) {
    left: 56px;
    animation: loader3 0.4s infinite;
}
@keyframes loader1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes loader3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes loader2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

.error-display {
    color: #fff;
    background-color: rgb(160, 0, 0);
    padding: 30px 30px 50px 30px;
}

img[alt='image'] {
    display: block;
    max-width: 200px;
    margin: 10px 0;
}

